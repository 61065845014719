<template>
  <v-container
    id="error-view"
    class="fill-height text-center pa-0 pa-md-3"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12" sm="9" md="8" lg="6" xl="4">
        <template>
          <v-card
            color="primary"
            dark
            class="pa-0 pa-md-3"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
          >
            <v-card-title class="py-9">
              <v-icon x-large>
                mdi-lock
              </v-icon>
              <div class="pl-3 text-h3">
                {{ $t("oturumAc") }}
              </div>
            </v-card-title>
            <v-card-text>
              <form @submit.prevent="submit">
                <v-text-field
                  ref="email"
                  v-model="email"
                  v-on:keyup.enter="login"
                  :prepend-icon="
                    $vuetify.breakpoint.smAndDown ? '' : 'mdi-login'
                  "
                  type="email"
                  :label="$t('eposta')"
                />
                <v-text-field
                  ref="password"
                  v-model="password"
                  :prepend-icon="
                    $vuetify.breakpoint.smAndDown ? '' : 'mdi-lastpass'
                  "
                  v-on:keyup.enter="login"
                  :label="$t('sifre')"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                />
              </form>
            </v-card-text>
            <v-card-actions class="pb-9">
              <v-btn
                large
                color="accent"
                depressed
                class="px-8 px-md-16"
                @click="login()"
              >
                {{ $t("girisYap") }}
              </v-btn>
              <v-spacer /><v-btn
                small
                color="white"
                plain
                text
                to="resetPassword"
              >
                {{ $t("sifreUnuttum") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      elevation="24"
      vertical
      max-width="600"
      color="error"
      timeout="6000"
      absolute
      right
      top
    >
      {{ errText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          {{ $t("kapat") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { giris, veri } from "./../db";
export default {
  name: "ErrorView",
  data: () => ({
    email: null,
    password: null,
    show: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match"
    },
    timeout: 6000,
    snackbar: false,
    errText: ""
  }),
  beforeCreate() {
    veri
      .ref()
      .child("userStatus")
      .get()
      .then(snapshot => {
        if (snapshot.exists() && snapshot.val().yeni) {
          this.$router.push("resetPassword");
        }
      });
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.email = " ";
      this.$refs.email.focus();
      setTimeout(() => {
        this.$refs.password.focus();
        this.$refs.email.blur();
      }, 10);
    },
    login() {
      giris.signInWithEmailAndPassword(this.email, this.password).then(
        user => {
          this.$router.replace("/");
        },
        err => {
          this.hasError = false;
          this.errText = err.message;
          this.snackbar = true;
        }
      );
    }
  }
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
}
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.v-application--is-ltr .v-input__prepend-outer {
  margin-right: 40px;
}
</style>
